import LeadForm from "../../components/LeadForm";
import company from "../../data/company.json";
import leadFormType from "../../data/leadFormType";

const Details = ({ address }) => {
  return (
    <div className="text-sm">
      <div className="h-full aspect-[2/1] rounded-md overflow-hidden mb-4">
        <img
          className="w-full h-full"
          src={address.imgUrl}
          alt={address.name}
          style={{ objectFit: "cover" }}
        />
        <a
          className="py-2 text-[13px] text-[#1a73e8] flex items-center gap-2 font-medium"
          href={address.google_maps_link}
          target="_blank"
          rel="noreferrer"
        >
          <span className="flex-grow mr-0.5 truncate">
            {address.google_maps_link}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-4 h-4 fill-[#1a73e8]"
          >
            <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
            <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
          </svg>
        </a>
      </div>
      <h2 className="text-base font-medium text-slate-700">{address.name}</h2>
      <h3 className="text-sm">{address.province}</h3>
      <p>{address.street}</p>
      <p>{`${address.city}, ${address.postalCode}`}</p>
      <div className="flex flex-col gap-1 py-4 font-medium">
        <a className="text-blue-600" href={`mailto:${address.email}`}>
          {address.email}
        </a>
        <a className="text-blue-600" href={`mailto:${address.contactNumber}`}>
          {address.contactNumber}
        </a>
      </div>
    </div>
  );
};

const page = () => {
  return (
    <div className="flex flex-col justify-center gap-16 px-6 pt-6 pb-16 md:flex-row">
      <div className="flex flex-col flex-grow md:items-center">
        <div className="md:max-w-[600px] w-full">
          <div className="w-full mb-12">
            <h3 className="text-lg font-semibold">Contact us</h3>
            <p className="mt-1 text-xs font-medium">
              Our friendly team would love to here from you!
            </p>
          </div>
          {/* <Form /> */}
          <LeadForm isEnquiry={true} type={leadFormType.contact} />
        </div>
      </div>
      <div className="w-[300px]">
        <ul className="flex flex-col gap-6">
          {company.addresses.map((address) => (
            <li key={address.name}>
              <Details address={address} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default page;
