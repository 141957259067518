import React from "react";
import { Link } from "react-router-dom";

const Page = () => {
  return (
    <div className="absolute top-0 left-0 h-screen w-screen flex flex-col items-center justify-center">
      <h2 className="font-semibold">Something went terribly wrong!</h2>
      <Link
        to="/"
        className="py-2 px-6 text-xs text-white rounded bg-custom-primary mt-4"
      >
        Take me home
      </Link>
    </div>
  );
};

export default Page;
