import React from "react";
import requests from "../../services/api/requests";
import { Await, defer, useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";

// import Accordion from "../../components/Accordion";
import Chip from "../../components/Chip";
import Loading from "./loading";

import {
  ChevronRightIcon,
  // WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

import { capitalizeStr } from "../../utilities/capitalizeStr";

const Card = ({
  rangeName,
  image_JPG,
  // image_WebP
  brand,
  model,
  fuel,
  transmission,
  price,
}) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-sm w-[300px] hover:drop-shadow">
      <div className=" bg-gray-200 relative w-[300px] h-[150px]">
        <img src={image_JPG} alt="" style={{ objectFit: "cover" }} />
      </div>
      <div className="px-4 py-4">
        <h3 className="text-xs">
          <span>{brand ?? "Suzuki"}</span> <span>{rangeName}</span>
        </h3>
        <p className="text-sm font-medium">
          <span>{model}</span>
        </p>
        <div className="flex gap-2 mt-3">
          <Chip label={capitalizeStr(fuel)} />
          <Chip label={capitalizeStr(transmission)} />
        </div>
        <p className="mt-3 text-sm">
          <span>Starting from</span>{" "}
          <span className="font-bold">{`R ${parseInt(price).toLocaleString(
            "en-ZA"
          )}`}</span>{" "}
          <span className="font-bold text-red-500">*</span>
        </p>
        <div className="flex items-center mt-4 -ml-1 text-xs font-medium">
          <div className="flex items-center justify-center w-3 h-3 mr-1 font-medium">
            <ChevronRightIcon />
          </div>
          <span>Show more</span>
        </div>
      </div>
    </div>
  );
};

const Page = () => {
  const { models, car } = useLoaderData();

  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={car}
        errorElement={<p className="px-6 py-4">Error loading models!</p>}
      >
        {(car) => {
          const data = models;

          // const picture =
          //   car?.model?.pictures[0]?.pictureWebPURL ??
          //   car?.model?.pictures[0]?.pictureNormalURL;

          // console.log({ car });

          return (
            <div className="w-full">
              <div className="relative flex flex-col ">
                <div className="bg-white px-6 md:px-6 py-4 md:sticky h-[100%]">
                  <div className="flex flex-col justify-center py-3 mb-0">
                    <h3 className="text-lg font-semibold">
                      Suzuki {capitalizeStr(car?.range?.rangeName)}
                    </h3>
                    <p className="text-sm font-medium">
                      Available Models {`(${data?.length ?? 0})`}
                    </p>
                  </div>
                  {/* <Sidebar
                    data={car?.model}
                    fuel={fuelSupport}
                    transmission={transmissionSupport}
                  /> */}
                </div>
                <div className="flex flex-wrap items-center justify-center flex-grow gap-6 px-6 py-6 bg-gray-50 md:justify-start md:items-start rounded-t-xl ">
                  {data?.map((item, i) => (
                    <Link key={i} to={`/range/model/${item?.model_ID}`}>
                      <Card
                        rangeName={car?.range?.rangeName}
                        // picture={picture}
                        {...item}
                      />
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-center py-8">
                <p className="text-[10px]">
                  <span className="font-bold text-red-500">*</span>{" "}
                  <span>
                    Prices may vary from quoted amount. Terms & Conditions
                    apply.
                  </span>
                </p>
              </div>
            </div>
          );
        }}
      </Await>
    </React.Suspense>
  );
};

export default Page;

export const fetchModels = async ({ params }) => {
  const rangeName = params.rangeName;

  const models = await requests.getModels(rangeName);

  models?.sort((a, b) => {
    if (a.price < b.price) {
      return -1;
    } else if (a.price > b.price) {
      return 1;
    } else return 0;
  });

  const car = requests.getCar(models[models?.length - 1]?.model_ID);
  return defer({
    models,
    car,
  });
};
