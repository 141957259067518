"use client";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// import Logo from "../public/suzuki logo.svg";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
// import { EnvelopeIcon } from "@heroicons/react/24/solid";

import Menu from "./Menu";
import menuItems from "../data/menu.json";

const Header = () => {
  return (
    <div className="flex py-2 pl-6 pr-6 max-h-10 items-center justify-center text-xs text-teal-700 font-medium">
      <div className="flex items-center">
        {/* <img
          className="h-5 w-5"
          src="/images/carter logo.svg"
          alt=""
          style={{ objectFit: "contain" }}
        /> */}
        <a
          className="ml-2"
          href="https://www.heycarter.co.za/"
          rel="noreferrer"
          target="_blank"
        >
          <img
            className="h-9 -mb-2"
            src="/images/carter logo.png"
            alt=""
            style={{ objectFit: "contain" }}
          />
        </a>
        {/* <p className="text-xs font-bold ml-2 text-custom-secondary">Carter</p> */}
      </div>
      {/* <a className="flex align-middle" href="mailto:hello@heycarter.co.za">
        <EnvelopeIcon className="h-4 mr-2" />
        <p>{email}</p>
      </a> */}
    </div>
  );
};

const Nav = ({ menuOpen, toggleMenu }) => {
  return (
    <div className="px-4 py-5 pr-6 flex justify-between items-center">
      <Link to="/">
        <div className="flex justify-left relative">
          <div>
            <img
              className="h-6"
              src="/images/suzuki logo.svg"
              alt=""
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </Link>
      <div className="hidden md:flex uppercase font-semibold text-xs gap-4 tracking-wide">
        {menuItems.map(({ title, route }) => {
          if (title.toUpperCase() === "VEHICLES") {
            return (
              <HashLink key={title} to={route}>
                Vehicles
              </HashLink>
            );
          } else {
            return (
              <Link key={title} to={route}>
                {title}
              </Link>
            );
          }
        })}
      </div>
      <div className="md:hidden flex">
        {!menuOpen && (
          <button
            className="flex items-center justify-center h-10 w-10"
            onClick={toggleMenu}
          >
            <Bars3Icon height={24} width={24} />
          </button>
        )}
        {menuOpen && (
          <button
            className="flex items-center justify-center h-10 w-10"
            onClick={toggleMenu}
          >
            <XMarkIcon height={24} width={24} />
          </button>
        )}
      </div>

      <Menu menuOpen={menuOpen} />
    </div>
  );
};

const NavBar = ({ ...company }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <Header {...company} />
      <Nav
        {...company}
        menuOpen={menuOpen}
        toggleMenu={() => setMenuOpen((prev) => !prev)}
      />
    </div>
  );
};

export default NavBar;
