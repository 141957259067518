import React from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import requests from "../../services/api/requests";

import Hero from "./Hero";
import Content from "./Content";
import Loading from "./Loading";

const Home = () => {
  const { data } = useLoaderData();

  return (
    <div>
      <Hero />
      <React.Suspense fallback={<Loading />}>
        <Await resolve={data} errorElement={<p>Error loading vehicles!</p>}>
          {(data) => <Content items={data ?? []} />}
        </Await>
      </React.Suspense>
    </div>
  );
};

export default Home;

export const fetchCars = async () => {
  const data = requests.getCars();
  return defer({
    data,
  });
};
