import { capitalizeStr } from "../../utilities/capitalizeStr";
// import ScrollTopButton from "../../components/ScrollTopButton";
import Accordion from "../../components/Accordion";

import {
  WrenchScrewdriverIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";

const Metric = ({ value, unit, label }) => {
  return (
    <div>
      <p className="inline-block">
        <span className="text-2xl">{value}</span>{" "}
        <span className="text-sm">{unit}</span>
      </p>
      <p className="text-[10px]">{label}</p>
    </div>
  );
};

// const Metrics = ({ model }) => {
//   const specifications = model?.specifications;

//   const performance = specifications?.performance;
//   const zeroToHundred =
//     performance?.find(({ key }) => key?.toLowerCase().includes("acceleration"))
//       ?.value ?? 0;

//   const maximumPower =
//     performance?.find(({ key }) => key?.toLowerCase().includes("power maximum"))
//       ?.value ?? 0;

//   const maximumTorque =
//     performance?.find(({ key }) =>
//       key?.toLowerCase().includes("torque maximum")
//     )?.value ?? 0;

//   return (
//     <div className="pt-4">
//       <div className="flex items-center gap-4 pb-6">
//         <Metric
//           value={parseFloat(zeroToHundred)}
//           unit="sec"
//           label="0-100 km/h"
//         />
//         <div className="bg-gray-300 h-6 w-[1px]"></div>
//         <Metric
//           value={parseFloat(maximumPower)}
//           unit="kW"
//           label="Maximum power"
//         />
//         <div className="bg-gray-300 h-6 w-[1px]"></div>
//         <Metric
//           value={parseFloat(maximumTorque)}
//           unit="Nm"
//           label="Maximum torque"
//         />
//       </div>
//       <p className="text-[10px] mb-2">
//         <span className="text-red-500 font-bold">*</span>{" "}
//         <span>Provided values are only average estimates. E & OE.</span>
//       </p>
//     </div>
//   );
// };

const Sidebar = ({ data, fuel, transmission }) => {
  console.log(data);
  const specifications = data?.specifications;

  //#region performance
  const performance = specifications?.performance;
  const zeroToHundred =
    performance?.find(({ key }) => key?.toLowerCase().includes("acceleration"))
      ?.value ?? 0;

  const maximumPower =
    performance?.find(({ key }) => key?.toLowerCase().includes("power maximum"))
      ?.value ?? 0;

  const maximumTorque =
    performance?.find(({ key }) =>
      key?.toLowerCase().includes("torque maximum")
    )?.value ?? 0;
  //#endregion

  //#region Fuel Economy
  const fuelEconomy = specifications?.fuelEconomy;
  const fuelConsumption =
    fuelEconomy?.find(({ key }) =>
      key?.toLowerCase().includes("fuel consumption")
    )?.value ?? "Unknown";

  const emissions =
    fuelEconomy?.find(({ key }) => key?.toLowerCase().includes("emissions"))
      ?.value ?? "Unknown";
  //#endregion

  //#region dimensions
  const dimensions = specifications?.dimensions;
  const doors =
    dimensions?.find(({ key }) => key?.toLowerCase().includes("doors"))
      ?.value ?? "Unknown";

  const seats =
    dimensions?.find(({ key }) => key?.toLowerCase().includes("seats"))
      ?.value ?? "Unknown";

  const fuelTankCapacity =
    dimensions?.find(({ key }) =>
      key?.toLowerCase().includes("fuel tank capacity")
    )?.value ?? "Unknown";
  //#endregion

  //#region
  const maintenance = specifications?.maintenance;
  const warrantyYears = maintenance?.find(({ key }) =>
    key?.toLowerCase().includes("warranty time")
  )?.value;

  const warrantyDistance = maintenance?.find(({ key }) =>
    key?.toLowerCase().includes("warranty distance")
  )?.value;
  //#endregion

  return (
    <div className="w-full">
      <div>
        <Accordion label="Perfomance" isOpen={true}>
          <div className="py-4">
            <div className="flex items-center gap-4 pb-6">
              <Metric
                value={parseFloat(zeroToHundred)}
                unit="sec"
                label="0-100 km/h"
              />
              <div className="bg-gray-300 h-6 w-[1px]"></div>
              <Metric
                value={parseFloat(maximumPower)}
                unit="kW"
                label="Maximum power"
              />
              <div className="bg-gray-300 h-6 w-[1px]"></div>
              <Metric
                value={parseFloat(maximumTorque)}
                unit="Nm"
                label="Maximum torque"
              />
            </div>
            <p className="text-[10px]">
              <span className="text-red-500 font-bold">*</span>{" "}
              <span>Provided values are only average estimates. E & OE.</span>
            </p>
          </div>
        </Accordion>
      </div>

      <div className="mt-2">
        <Accordion label="Specifications" isOpen={true}>
          <div>
            <div className="border-b py-4">
              <Property label="Fuel type" value={fuel ?? "Unknown"} />
              <Property label="Fuel capacity" value={fuelTankCapacity} />
              <Property label="Transmission" value={transmission} />
              <Property label="Doors" value={doors} />
              <Property label="Seats (quantity)" value={seats} />
              <Property label="Fuel consumption(avg)" value={fuelConsumption} />
              <Property label="CO2 emissions(avg)" value={emissions} />
            </div>
            {warrantyDistance && warrantyYears && (
              <div>
                <div className="flex items-center">
                  <div className="flex items-center justify-center h-4 w-4 mr-2">
                    <WrenchScrewdriverIcon />
                  </div>
                  <p className="text-[13px] py-3">
                    <span>{`${warrantyYears}${
                      warrantyDistance &&
                      `/${
                        parseFloat(warrantyDistance)?.toLocaleString("en-ZA") +
                        " km"
                      } stardard warranty`
                    }`}</span>{" "}
                    <span className="text-red-500 font-bold">*</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </Accordion>
      </div>
    </div>
  );
};

const Property = ({ label, value }) => {
  return (
    <div className="flex justify-between text-[13px] py-2">
      <p>{capitalizeStr(label)}</p>
      <p className="font-medium">{capitalizeStr(value)}</p>
    </div>
  );
};

const Section = ({ title, data }) => {
  return (
    <div className="max-w-[500px] px-6 mt-8">
      <h4 className="text-[11px] uppercase font-semibold mb-2">{title}</h4>
      <ul>
        {data?.map(({ key, value }) => (
          <Property
            key={key}
            label={key}
            value={value === "std" ? "Standard" : value}
          />
        ))}
      </ul>
    </div>
  );
};

const Content = ({ ...data }) => {
  const fuel = data?.model?.specifications?.engine?.find(
    (engine) => engine?.key?.toLowerCase() === "fuel type"
  )?.value;

  const transmission = data?.model?.specifications?.drive?.find(
    (drive) => drive?.key?.toLowerCase() === "gearshift"
  )?.value;

  return (
    <div className="w-full relative">
      <div className="bg-white border-t sticky top-24 pt-4 pb-6 px-6">
        <h3 className="font-normal text-2xl">Specifications</h3>
        <p className="text-[13px] font-medium">{`${data?.brand?.brandName} ${data?.range?.rangeName} ${data?.model?.modelName}`}</p>
      </div>
      {/* <div className="py-2 px-6">
        <h4 className="text-[11px] uppercase font-semibold">key metrics</h4>
        <Metrics {...data} />
      </div> */}
      <div className="px-6 w-full">
        <Sidebar data={data?.model} fuel={fuel} transmission={transmission} />
      </div>
      <Accordion
        renderLabel={() => (
          <div className="flex items-center text-sm font-medium mt-4 -ml-1 text-blue-600 px-6 py-2">
            <div className="flex items-center font-medium justify-center h-4 w-4 mr-1">
              <ChevronRightIcon />
            </div>
            <span>Show more</span>
          </div>
        )}
      >
        <Section title="Features" data={data?.model?.specifications?.gadgets} />
        <Section title="Safety" data={data?.model?.specifications?.safety} />
        <Section
          title="Enviromental"
          data={data?.model?.specifications?.environmental}
        />
        <Section title="Drive" data={data?.model?.specifications?.drive} />
        <Section title="Engine" data={data?.model?.specifications?.engine} />
      </Accordion>

      {/* <div className="px-6 pt-6">
        <ScrollTopButton />
      </div> */}
    </div>
  );
};

export default Content;
