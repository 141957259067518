const TextArea = ({
  value,
  label,
  placeholder,
  isDisabled,
  isRequired,
  error,
  onChange,
}) => {
  return (
    <div className="w-full">
      <div className="flex mb-2">
        {label && (
          <div className="flex-grow text-[13px] mb-2 font-medium">
            <span>
              {label} {isRequired && <span className="text-red-400">*</span>}
            </span>
          </div>
        )}
        <div className="flex items-center h-6">
          <p className="font-medium text-red-600 text-[11px]">{error}</p>
        </div>
      </div>

      <textarea
        value={value}
        placeholder={placeholder}
        className={`min-h-[128px] text-sm py-2 px-2 focus:outline-2 outline-custom-tertiary w-full disabled:text-gray-400 disabled:bg-gray-100 resize-none border rounded`}
        disabled={isDisabled}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
