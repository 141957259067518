const emailDomains = [
  "yahoo.com",
  "hotmail.com",
  "heycarter.co.za",
  "gmail.com",
  "aol.com",
  "live.com",
  "googlemail.com",
  "facebook.com",
  "outlook.com",
  "icloud.com",
];

export default emailDomains;
