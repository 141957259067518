import React from "react";
import { useLoaderData, useLocation } from "react-router-dom";

import LeadForm from "../../components/LeadForm";
import leadFormType from "../../data/leadFormType";

const Page = () => {
  const data = useLoaderData();
  const url = useLocation();
  const dealerName = url.search?.split("?q=")[1].split("%20").join(" ");

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-center">
      <div className="max-w-[300px] px-6">
        <h2 className="text-2xl mb-2 font-semibold">Service appointment</h2>
        <p className="text-xs">
          Make a service appointment to keep your car running smoothly and at
          its full potential.
        </p>
      </div>
      <div className="flex-grow flex justify-center mt-8 p-6">
        <div className="max-w-[600px]">
          {dealerName && (
            <h4 className="mb-6 text-custom-primary font-semibold">
              <p className="font-medium text-[10px] uppercase">dealership</p>
              <p>{dealerName}</p>
            </h4>
          )}
          <LeadForm type={leadFormType.bookService} data={data} />
        </div>
      </div>
    </div>
  );
};

export default Page;

export const fetchServiceData = ({ params }) => {
  return { data: params.dealerID };
};
