import { Link } from "react-router-dom";

const Item = ({ pictures, range, min_price, min_price_monthly }) => {
  let instalment = min_price_monthly;
  let priceInclVat = min_price;

  return (
    <div className="overflow-hidden bg-white border border-gray-200 rounded-lg hover:drop-shadow">
      <div className=" bg-gray-200 relative w-[340px] h-[170px]">
        <img
          src={pictures[0]?.pictureWebPURL ?? pictures[0]?.pictureNormalURL}
          alt=""
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="px-3 py-4">
        <h3 className="mb-1 text-lg font-semibold">{range}</h3>
        <p className="mb-3 text-sm">
          <span>From</span>{" "}
          <span className="font-bold">{`R ${parseInt(instalment).toLocaleString(
            "en-ZA"
          )}`}</span>{" "}
          <span>pm</span> <span>or</span>{" "}
          <span className="font-bold">{`R ${priceInclVat?.toLocaleString(
            "en-ZA"
          )}`}</span>{" "}
          <span className="font-bold text-red-500">*</span>
        </p>
        <div className="text-sm font-medium underline underline-offset-2">
          Learn more
        </div>
      </div>
    </div>
  );
};

const Content = ({ items }) => {
  return (
    <section
      id="vehicles"
      className="flex flex-col items-center justify-center py-12"
    >
      <div className="mb-12">
        <h1 className="text-3xl font-extrabold text-center">Vehicles</h1>
        <p className="text-sm font-medium text-center">
          {items?.length} vehicles in stock
        </p>
      </div>
      <div className="flex justify-center flex-wrap xl:max-w-[1200px] gap-6">
        {items?.map((item, i) => (
          <Link key={i} to={`/range/${item?.range}`}>
            <Item {...item} />
          </Link>
        ))}
      </div>
      <p className="mt-12 text-[10px]">
        <span className="font-bold text-red-500">*</span>{" "}
        <span>
          Prices may vary from quoted amount. Terms & Conditions apply.
        </span>
      </p>
    </section>
  );
};

export default Content;
