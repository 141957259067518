import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requests from "../../services/api/requests";

const Hero = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      setData(await fetchHeroData());

      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="relative">
      <div className="relative">
        <div className="w-full md:aspect-w-2 md:aspect-h-1 bg-gray-300 rounded-tl-2xl rounded-tr-2xl overflow-hidden relative">
          {!loading && (
            <img
              src="/images/hero.webp"
              alt=""
              style={{ objectFit: "cover" }}
            />
          )}

          <div className="absolute top-0 left-0 h-full w-full bg-black/20"></div>
        </div>
        {loading && (
          <div className="absolute top-0 left-0 flex justify-center items-center animate-pulse w-full h-full bg-gray-500 rounded-tl-2xl rounded-tr-2xl overflow-hidden">
            <svg
              className="w-12 h-12 text-gray-200"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 640 512"
            >
              <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
            </svg>
          </div>
        )}
        {!loading && (
          <div className="absolute bottom-0 md:top-0 right-0 m-2 hidden md:block md:m-14 text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
            <h2 className="uppercase font-black mb-3 text-xl md:text-3xl">
              Suzuki S-Presso
            </h2>
            <p className="text-l font-semibold">
              {/* Practical & affordable 4X4 <br /> that’s lots of fun to drive. */}
              The Suzuki S-Presso is the <br /> perfect extension of you.
            </p>
            <div className="flex items-center md:mt-8 gap-6">
              <p className="text-xs">
                <span>From</span>{" "}
                <span className="font-bold">{`R ${parseInt(
                  data?.model?.price
                ).toLocaleString("en-ZA")}`}</span>{" "}
                <span className="font-bold text-red-600 ml-1">*</span>
              </p>
              <Link
                className="text-[10px] font-semibold uppercase rounded-full px-5 py-3 text-white bg-custom-tertiary"
                to={`/range/S-presso`}
              >
                Learn more
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="mx-4 my-6 md:hidden md:m-14">
        <h2 className="uppercase font-black mb-3 text-xl md:text-3xl">
          Suzuki S-Presso
        </h2>
        <p className="text-l">
          The Suzuki S-Presso is the perfect extension of you. It allows you to
          Do You, enabling you to take up spaces that you never thought possible
        </p>
        <div className="flex items-center mt-8 gap-6">
          <p className="text-xs">
            <span>From</span>{" "}
            <span className="font-bold">{`R ${parseInt(
              data?.model?.price
            ).toLocaleString("en-ZA")}`}</span>{" "}
            <span className="font-bold text-red-600 ml-1">*</span>
          </p>
          <Link
            className="text-[10px] font-semibold uppercase rounded-full px-5 py-3 text-white bg-custom-tertiary tracking-wider"
            to={`/range/S-Presso`}
          >
            Learn more
          </Link>
        </div>
      </div>
      <div className="bg-primary px-12 py-8 text-white bg-custom-primary">
        <h1 className="text-sm md:text-2xl font-black text-center uppercase mb-3">
          Carter Suzuki!
        </h1>

        <div className="flex text-sm justify-center">
          <p className="max-w-[750px] text-center font-medium">
            <span>
              Welcome to Carter Suzuki. Expect to be delighted by our new Suzuki
              range and quality vehicles.
            </span>{" "}
            <span className="hidden md:inline">
              Experience our quality service, as well as free advice and
              assistance with all your motoring needs. Come and visit our
              dealership, meet our team and enjoy our warm hospitality.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;

export const fetchHeroData = async () => {
  const models = await requests.getModels("S-Presso");

  if (models?.length > 0) {
    models?.sort((a, b) => {
      if (a.price < b.price) {
        return -1;
      } else if (a.price > b.price) {
        return 1;
      } else return 0;
    });

    return {
      model: models[0],
    };
  }

  return null;
};
