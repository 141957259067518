"use client";
import { useState } from "react";

const ImageX = ({ src, ...props }) => {
  // const [loading, setLoading] = useState(true);

  return (
    <div className="bg-gray-100 relative w-full h-full">
      <img
        {...props}
        src={src}
        alt="..."
        style={{ objectFit: "cover" }}
        // onLoadingComplete={() => {
        //   setLoading(false);
        // }}
      />

      {/* {loading && (
        <div className="bg-gray-300 animate-pulse absolute top-0 left-0 w-full h-full z-10"></div>
      )} */}
    </div>
  );
};

export default ImageX;
